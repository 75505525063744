.top_zj[data-v-6dbd57e3] {
  padding: 10px 20px 20px;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
}
.top_zj .top_content[data-v-6dbd57e3] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main[data-v-6dbd57e3] {
  padding: 0 20px 10px;
}
.iconfont[data-v-6dbd57e3] {
  font-size: 13px;
  margin-right: 4px;
}
.hint[data-v-6dbd57e3] {
  color: var(--themeColor, #17a2b8);
  margin: 20px 0 10px;
  font-size: 14px;
}
.fs[data-v-6dbd57e3] {
  margin-right: 3px;
  font-weight: 600;
  color: var(--themeColor, #17a2b8);
}